import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PieChart } from 'react-minimal-pie-chart';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../constants/routes';
import { FreeTrialContext } from '../../../../context/freeTrialContext';
import { UserContext } from '../../../../context/userContext';
import { gray_color2, scope1_color, scope2_color, scope3_color } from '../../../../styles/colors';
import { Organization } from '../../../../types/entities/organization';
import { checkFreePlan } from '../../../../utils/checkFreePlan';
import formatNumber from '../../../../utils/formatNumber';
import numberToDecimal from '../../../../utils/numberToDecimal';
import Button from '../../../ui/button/Button';
import LoaderCard from '../../../ui/loaders/loaderCard/LoaderCard';
import ScopeTag from '../../../ui/scopeTag/ScopeTag';
import TooltipWrapper from '../../../ui/tooltip/TooltipWrapper';
import './styles.scss';
import useSelectedOrganization from '../../../../customHooks/useSelectedOrganization';
import Modal from '../../../ui/modal/Modal';
import PaiReportDataForm from '../../../communicate/paiReport/paiReportDataForm/PaiReportDataForm';
import Tooltip from 'components/ui/tooltip/Tooltip';
import * as amplitude from '@amplitude/analytics-browser';
import { EVENTS } from 'constants/amplitude';
import Icon from 'components/ui/icon/Icon';
import useRole from 'customHooks/useRole';

type Props = {
  data: ScopeData[];
  organization: Organization;
  startDate: Date;
  endDate: Date;
  loading: boolean;
  disabled?: boolean;
};
function CompanyEnvironmentImpact({
  data,
  organization,
  startDate,
  endDate,
  loading,
  disabled = false
}: Props) {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const user = useContext(UserContext);

  const selectedOrganization = useSelectedOrganization();
  const { isFundRole } = useRole();

  const freeTrialContext = useContext(FreeTrialContext);

  const [showGeneratePAIReport, setShowGeneratePAIReport] = useState(false);

  let pieChartData = [{ title: '', value: 100, color: gray_color2, key: 'bg' }];
  let totalCo2 = 0;

  let scope1 = 0;
  let scope2 = 0;
  let scope3 = 0;

  const renderFilledData = () => {
    return (
      <div className='company-environment-scopes__information'>
        <ScopeTag scope={1} value={scope1} totalCo2={totalCo2} />
        <ScopeTag scope={2} value={scope2} totalCo2={totalCo2} />
        <ScopeTag scope={3} value={scope3} totalCo2={totalCo2} />
      </div>
    );
  };

  const renderFundDataButtons = () => {
    return (
      <div className='button-wrapper'>
        <Button
          lookAndFeel='primary'
          size='medium'
          text={t('dashboard.goToMyInvestments')}
          onClick={() => navigate(ROUTES.MEASURE_COMPANIES)}
        />
        <Button
          lookAndFeel='secondary'
          size='medium'
          text={t('dashboard.generateReport')}
          onClick={() => setShowGeneratePAIReport(true)}
        />
      </div>
    );
  };
  const renderUnfilledData = () => {
    let buttons = null;
    if (!isFundRole) {
      buttons = selectedOrganization?.is_group_fund ? (
        renderFundDataButtons()
      ) : (
        <div className='button-wrapper'>
          <Button
            lookAndFeel='primary'
            text={t('dashboard.startMeasureButton')}
            onClick={navigateToMeasure}
            size='medium'
          />
        </div>
      );
    }
    return (
      <div className='company-environment-scopes__information'>
        <span className='headline4-font on-light-text-color'>
          {t('dashboard.startMeasuringImpact')}
        </span>
        <span className='subtitle3-font on-light-text-color'>
          {t('dashboard.completeInformation')}
        </span>
        {buttons}
      </div>
    );
  };

  const generatePDF = () => {
    amplitude.track(EVENTS.BUTTON_CLICKED, {
      org_id: selectedOrganization?.id,
      org_name: selectedOrganization?.company_name,
      is_internal: user?.email.includes('@dcycle.io') ? 'Internal user' : 'External user',
      button_clicked: 'Generate Dcycle Report (in Dashboard)'
    });
    if (organization.subscription_plan !== 'free_plan') {
      navigate('?showOrgDescriptionModalDcycle=true', { replace: false });
    }
  };

  const navigateToMeasure = () => {
    navigate(ROUTES.MEASURE);
  };

  const renderCo2Information = () => {
    let fontSize = 20;
    const maxDigits = 5;
    if (numberToDecimal(totalCo2).toString().length > maxDigits) {
      fontSize = 20 - (numberToDecimal(totalCo2).toString().length - maxDigits) - 2;
    }
    return (
      <div className='co2-information'>
        <div
          className={`co2-value highlight-text-color co2-dashboard-font`}
          style={{ fontSize: `${fontSize}px` }}>
          {formatNumber(numberToDecimal(totalCo2))}
        </div>
        <span className='co2-units on-light-text-color body1-font'>t CO₂ eq.</span>
      </div>
    );
  };

  const downloadPNG = () => {
    if (organization.subscription_plan !== 'free_plan') {
      window.open(`${ROUTES.SNIPPET}?start_date=${startDate}&end_date=${endDate}`);
    } else {
      freeTrialContext.setShowPremiumFeatureModal(true);
    }
  };

  if (data && data.filter((elem) => elem.total_emissions > 0).length > 0) {
    data.forEach((value) => {
      totalCo2 += value.total_emissions;

      if (value.scope === 1) {
        scope1 += value.total_emissions;
      }
      if (value.scope === 2) {
        scope2 += value.total_emissions;
      }
      if (value.scope === 3) {
        scope3 += value.total_emissions;
      }
    });

    totalCo2 = totalCo2 / 1000; // convert to tons
    scope1 = scope1 / 1000; // convert to tons
    scope2 = scope2 / 1000; // convert to tons
    scope3 = scope3 / 1000; // convert to tons

    let scope1Value = (scope1 * 100) / totalCo2;
    let scope2Value = (scope2 * 100) / totalCo2;
    let scope3Value = (scope3 * 100) / totalCo2;

    if (scope1Value < 1 && scope1 !== 0) scope1Value = 0.1;
    if (scope2Value < 1 && scope2 !== 0) scope2Value = 2.1;
    if (scope3Value < 1 && scope3 !== 0) scope3Value = 4.1;

    pieChartData = [
      { title: '', value: scope1Value, color: scope1_color, key: 'scope1' },
      { title: '', value: scope2Value, color: scope2_color, key: 'scope2' },
      { title: '', value: scope3Value, color: scope3_color, key: 'scope3' }
    ];

    pieChartData.sort((a, b) => {
      return b.value - a.value;
    });
  }

  if (loading || !organization) {
    return <LoaderCard />;
  }

  return (
    <div className='card dashboard-card' id='step2'>
      <div className='dashboard-card__header'>
        <div className='flex gap-2 items-center'>
          <h4 className='headline4-font on-light-text-color'>{t('dashboard.environmentImpact')}</h4>
          <Tooltip text={t('ghgReport.scope2CalculationDisclaimer')} />
        </div>
        <div className='download-button'>
          {organization.subscription_plan !== 'free_plan' ? (
            !isFundRole && (
              <TooltipWrapper
                text={t('dashboard.viewAndDownloadReport')}
                position='left'
                size='small'>
                <Button
                  iconNode={<Icon icon={'download'} color={'gray-dark'} />}
                  lookAndFeel='secondary'
                  onClick={downloadPNG}
                  size='tiny'
                />
              </TooltipWrapper>
            )
          ) : (
            <button className='download-locked' onClick={downloadPNG}>
              <span className='small-card-font secondary-text-color'>
                {t('dashboard.startCommunicatingYourImpact')}
              </span>
              <img src='/images/icons/lockPlain.svg' alt='lock' />
            </button>
          )}
        </div>
      </div>
      <div className='company-environment-wrapper__body small-device-flex'>
        <div className='company-environment-wrapper__two'>
          <div className='co2-wrapper round'>
            <div className='co2-progress-bar'>
              <PieChart // your data
                data={pieChartData}
                // width and height of the view box
                viewBoxSize={[100, 100]}
                lineWidth={24}
                totalValue={100}
                rounded
                animate></PieChart>
              {renderCo2Information()}
            </div>
          </div>
        </div>
        <div className='company-environment-wrapper__two'>
          {!disabled ? renderFilledData() : renderUnfilledData()}
          {!disabled && !isFundRole && !selectedOrganization?.is_group_fund && (
            <div className='button-wrapper'>
              {!user?.selectedGrouping && (
                <Button
                  lookAndFeel={checkFreePlan(organization) ? 'blocked' : 'secondary'}
                  text={t('dashboard.generateReport')}
                  onClick={generatePDF}
                  size='medium'
                />
              )}
            </div>
          )}
          {!disabled &&
            !isFundRole &&
            selectedOrganization?.is_group_fund &&
            renderFundDataButtons()}
        </div>
      </div>
      <Modal
        show={showGeneratePAIReport}
        onClose={() => setShowGeneratePAIReport(false)}
        width='584px'
        maxWidth='584px'>
        <PaiReportDataForm closeModal={() => setShowGeneratePAIReport(false)} />
      </Modal>
    </div>
  );
}

export default CompanyEnvironmentImpact;
