import { Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { BOOK_MEETING_URL } from '../../../constants';
import { SMALL_SCREEN } from '../../../constants/screenSizes';
import { FilterDatesContext } from '../../../context/filterDatesContext';
import { FreeTrialProvider } from '../../../context/freeTrialContext';
import { UserContext } from '../../../context/userContext';
import useScreenSize from '../../../customHooks/useScreenSize';

import { usePremiumRoutes } from '../../../customHooks/usePremiumRoutes';
import { checkExpiredPlan, checkFreePlan } from '../../../utils/checkFreePlan';
import { formatDate } from '../../../utils/formatDate';
import { localStorageGetItem, localStorageSetItem } from '../../../utils/localStorage';
import GHGReportDataForm from '../../communicate/ghgReport/GHGReportDataForm/GHGReportDataForm';
import Iso14064Report from '../../communicate/iso14064Report/Iso14064Report';
import PaiReportDataForm from '../../communicate/paiReport/paiReportDataForm/PaiReportDataForm';
import DcycleReportDataForm from '../../communicate/report/dcycleReportDataForm/DcycleReportDataForm';
import SnippetReportDataForm from '../../communicate/snippet/snippetReportDataForm/SnippetReportDataForm';
import FreePlanReminder from '../../freeTrials/freePlanReminder/FreePlanReminder';
import PremiumFeatureAdvise from '../../freeTrials/premiumFeatureAdvise/PremiumFeatureAdvise';
import SubscriptionReminder from '../../freeTrials/subscriptionReminder/SubscriptionReminder';
import CalendlyMeeting from '../../ui/calendlyMeeting/CalendlyMeeting';
import MenuBurger from '../../ui/menuBurger/MenuBurger';
import Modal from '../../ui/modal/Modal';
import SideMenu from '../SideMenu/SideMenu';
import Header from '../header/Header';
import PoweredByDcycle from '../poweredByDcycle/PoweredByDcycle';
import './styles.scss';
import { ESGModeContext } from '../../../context/esgModeContext';
import CSRDModal from './components/CSRDModal';
import SmallBanner from '../smallBanner/smallBanner';
import { useFeatureFlags } from 'customHooks/useFeatureFlags';
import Scope1_2 from 'components/communicate/scope1_2/Scope1_2';

type Props = {
  children: ReactNode;
  showFilterDates?: boolean;
};

const EXCLUDED_ORGANIZATION_IMPROVING_BANNER = [
  '7d8cee16-99fc-4fe2-88e1-6b35225829be',
  '6f56c600-954c-4e09-ad00-169bfcd2b690',
  '50d90bc3-b57d-4fd1-ba2a-62bbbdfb3666',
  '47d53764-459f-4fb0-a234-d567a5c9c19c',
  'f33585e8-c18a-48ac-9a2d-408e2de73c5e',
  '00746ea8-0056-4dbb-81de-477e63d78262',
  '0a21bf96-9361-4db3-926e-4ab83c99eb00',
  '1fec0d10-8fda-4640-bf2f-ec0bfba93296',
  'f8924b90-eee3-4fa8-92f7-a108b6876a2a',
  'd7fad5bb-c9b7-41fe-b049-996051eeea3a',
  '8446172e-1b6d-432c-8f0a-d1f2f56b43a6',
  '0aa4459c-04f7-4f38-852b-427d33c57833',
  '2325da33-153c-40f9-8f1e-5cac5e7aa0f7',
  '1fc48c72-4720-4a79-acc1-6c6f61a7eab8',
  'd2e04408-f6d3-4fa3-b2a0-3853e7e66991',
  '389b6a46-4cf9-4ecd-8d6b-675ded98ed50',
  '03b0895e-20bf-4cc7-bea2-04e9369bcb98',
  '7560d9be-878a-47ee-a181-5e453cfc6ed4',
  'aa65b6d7-070d-4130-add4-af33f185599e',
  '57d5667d-3de2-4818-aa39-a2067ce63ea5',
  '5faa3006-227c-42a6-8f83-c78c31ecce3a',
  '1fcb7b76-1f82-475a-899f-2be6eb36a93b',
  '4aa55d47-b6a0-4367-b323-9a1dc619be2c',
  '4df2b861-12ca-498b-a6f5-d4cd4bbefa46',
  '5324fc14-1755-439a-9df8-656e9fb08b43',
  'd68be058-a3b2-42a8-8468-922e5a67c3f4',
  'a10d315a-ca14-4e22-b2dc-809969dba4c1',
  'da6994e0-dde3-4117-b326-c0909d2485c2',
  '804edcb8-27a4-419b-9709-634779f0ee2c'
];

function MainLayout({ children, showFilterDates = false }: Props) {
  const flags = useFeatureFlags();
  const location = useLocation();
  const { width } = useScreenSize();
  const user = useContext(UserContext);

  const PREMIUM_ROUTES = usePremiumRoutes();

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  today.setDate(today.getDate());
  const oneYearAgo = new Date();
  oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
  oneYearAgo.setMonth(0);
  oneYearAgo.setDate(1);
  oneYearAgo.setHours(0, 0, 0, 0);

  const startDateLocalStorage = localStorageGetItem('start_date_dashboard');
  const endDateLocalStorage = localStorageGetItem('end_date_dashboard');

  const [startDate, setStartDate] = useState<string>(
    startDateLocalStorage ? startDateLocalStorage : formatDate(oneYearAgo)
  );
  const [endDate, setEndDate] = useState<string>(
    endDateLocalStorage ? endDateLocalStorage : formatDate(today)
  );
  const [isOpen, setIsOpen] = useState(false);

  const [dateError, setDateError] = useState<ErrorType>();

  const foundOrganization = user?.organizations?.find(
    (org) => org.id === user.selectedOrganization
  );

  const [freePlanExpired, setFreePlanExpired] = useState(false);
  const [showPremiumFeatureModal, setShowPremiumFeatureModal] = useState(
    PREMIUM_ROUTES.find((elem) => elem === location.pathname) &&
      foundOrganization &&
      checkFreePlan(foundOrganization)
      ? true
      : false
  );

  const [showOrgDescriptionModalGHG, setShowOrgDescriptionModalGHG] = useState(false);
  const [showOrgDescriptionModalDcycle, setShowOrgDescriptionModalDcycle] = useState(false);
  const [showOrgDescriptionModalSnippet, setShowOrgDescriptionModalSnippet] = useState(false);
  const [showOrgDescriptionModalPai, setShowOrgDescriptionModalPai] = useState(false);
  const [showOrgDescriptionModalIso14064, setShowOrgDescriptionModalIso14064] = useState(false);
  const [showCSRDModal, setShowCSRDModal] = useState(false);
  const [showScope1_2Modal, setShowScope1_2Modal] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const [mode, setMode] = useState<string>('environmental');

  const [showBanner, setShowBanner] = useState(
    // DCYC-5293 - Hide the improving Dcycle banner
    // !EXCLUDED_ORGANIZATION_IMPROVING_BANNER.includes(user?.selectedOrganization ?? '')
    false
  );
  const webinarBannerClick = () => {
    // open https://hubs.ly/Q033sWQZ0 in new tab
    window.open('https://hubs.ly/Q033sWQZ0', '_blank');
    setShowBanner(false);
  };

  const improvingDcycleBannerClick = () => {
    window.open('https://headwayapp.co/dcycle-changelog/mejoras-en-marcha-309607', '_blank');
    setShowBanner(false);
  };

  useEffect(() => {
    setShowPremiumFeatureModal(
      PREMIUM_ROUTES.find((elem) => elem === location.pathname || location.search.includes(elem)) &&
        foundOrganization &&
        checkFreePlan(foundOrganization)
        ? true
        : false
    );
  }, [location]);

  useEffect(() => {
    const handleOrgDescriptionModal = (
      key: string,
      setModal: Dispatch<SetStateAction<boolean>>
    ) => {
      if (searchParams.get(key) && foundOrganization?.subscription_plan !== 'free_plan') {
        setModal(true);
        searchParams.delete(key);
        setSearchParams(searchParams);
      }
    };
    if (foundOrganization) {
      const expirationDate = new Date(foundOrganization?.subscription_plan_expire_date);
      if (expirationDate < new Date()) {
        setFreePlanExpired(true);
        return;
      }
      setFreePlanExpired(false);
    }
    handleOrgDescriptionModal('showOrgDescriptionModalGHG', setShowOrgDescriptionModalGHG);
    handleOrgDescriptionModal('showOrgDescriptionModalDcycle', setShowOrgDescriptionModalDcycle);
    handleOrgDescriptionModal('showOrgDescriptionModalSnippet', setShowOrgDescriptionModalSnippet);
    handleOrgDescriptionModal('showOrgDescriptionModalPai', setShowOrgDescriptionModalPai);
    handleOrgDescriptionModal(
      'showOrgDescriptionModalIso14064',
      setShowOrgDescriptionModalIso14064
    );
    handleOrgDescriptionModal('showScope1_2Modal', setShowScope1_2Modal);
  }, [searchParams, JSON.stringify(foundOrganization)]);

  const onCloseModal = () => {
    setShowOrgDescriptionModalGHG(false);
    setShowOrgDescriptionModalDcycle(false);
    setShowOrgDescriptionModalSnippet(false);
    setShowOrgDescriptionModalPai(false);
    setShowOrgDescriptionModalIso14064(false);
  };

  const onClosePremiumFeatureModal = () => {
    setShowPremiumFeatureModal(false);
  };

  // const onCloseSurveyModal = () => {
  //   // set local storage to not show survey modal
  //   localStorage.setItem('hideSurveyModal', 'true');
  //   emptySurveys();
  // };

  const renderModal = () => {
    const expired = checkExpiredPlan(foundOrganization);

    if (expired) {
      return <FreePlanReminder />;
    }
    return <PremiumFeatureAdvise />;
  };
  if (!user) {
    return null;
  }
  return (
    <section className='main-app app-bg-color'>
      {showBanner ? (
        <SmallBanner
          text='smallBanner.improvingDcycle'
          buttonFunction={() => improvingDcycleBannerClick()}
          buttonText='smallBanner.moreInformation'
        />
      ) : null}
      <SubscriptionReminder />
      <FreeTrialProvider
        setShowPremiumFeatureModal={setShowPremiumFeatureModal}
        showPremiumFeatureModal={showPremiumFeatureModal}>
        <FilterDatesContext.Provider
          value={{ startDate, setStartDate, endDate, setEndDate, dateError, setDateError }}>
          <ESGModeContext.Provider value={{ mode, setMode }}>
            <div
              className='main-app-wrapper'
              style={{
                paddingBottom:
                  foundOrganization?.subscription_plan !== 'free_plan' ? '5px' : '10px',
                paddingTop: foundOrganization?.subscription_plan !== 'free_plan' ? '20px' : '30px'
              }}>
              {(width > SMALL_SCREEN || (width < SMALL_SCREEN && isOpen)) && <SideMenu />}
              {!freePlanExpired ? (
                <div className='main-app-content'>
                  <MenuBurger setIsOpen={setIsOpen} open={isOpen} />
                  {user && <Header showFilterDates={showFilterDates} />}
                  {PREMIUM_ROUTES.find((elem) => elem === location.pathname) &&
                  foundOrganization &&
                  checkFreePlan(foundOrganization) ? (
                    <></>
                  ) : (
                    children
                  )}
                  {/* <Footer /> */}
                </div>
              ) : (
                <div className='main-app-content'>
                  <MenuBurger setIsOpen={setIsOpen} open={isOpen} />
                  {user && <Header showFilterDates={showFilterDates} />}
                  <CalendlyMeeting url={BOOK_MEETING_URL} />
                </div>
              )}
            </div>

            <Modal
              show={showPremiumFeatureModal}
              onClose={onClosePremiumFeatureModal}
              width='550px'
              maxWidth='550px'>
              {renderModal()}
            </Modal>
          </ESGModeContext.Provider>
        </FilterDatesContext.Provider>
      </FreeTrialProvider>
      {foundOrganization?.logo_url && <PoweredByDcycle />}
      <Modal
        show={showOrgDescriptionModalGHG}
        onClose={onCloseModal}
        width='800px'
        maxWidth='800px'>
        <GHGReportDataForm onClose={onCloseModal} />
      </Modal>
      <Modal
        show={showOrgDescriptionModalDcycle}
        onClose={onCloseModal}
        width='550px'
        maxWidth='550px'>
        <DcycleReportDataForm onCloseModal={onCloseModal} />
      </Modal>
      <Modal
        show={showOrgDescriptionModalSnippet}
        onClose={onCloseModal}
        width='584px'
        maxWidth='584px'>
        <SnippetReportDataForm user={user} closeModal={onCloseModal} />
      </Modal>
      <Modal
        show={showOrgDescriptionModalPai}
        onClose={onCloseModal}
        width='584px'
        maxWidth='584px'>
        <PaiReportDataForm closeModal={onCloseModal} />
      </Modal>
      <Modal
        show={showOrgDescriptionModalIso14064}
        onClose={onCloseModal}
        width='584px'
        maxWidth='584px'>
        <Iso14064Report closeModal={onCloseModal} />
      </Modal>
      {/* <Modal
        show={
          !!flags?.surveys &&
          formIds.length > 0 &&
          !tutorial?.active &&
          (!localStorage.getItem('hideSurveyModal') ||
            localStorage.getItem('hideSurveyModal') === 'false')
        }
        onClose={onCloseSurveyModal}
        width='700px'>
        <Survey formIds={formIds} />
      </Modal> */}
      <Modal.WithPortal
        width='600px'
        maxWidth='600px'
        show={showCSRDModal}
        onClose={() => {
          localStorageSetItem('csrdModalSeen', true);
          setShowCSRDModal(false);
        }}>
        <CSRDModal
          handleClose={() => {
            localStorageSetItem('csrdModalSeen', true);
            setShowCSRDModal(false);
          }}
        />
      </Modal.WithPortal>
      <Modal.WithPortal
        width='600px'
        maxWidth='600px'
        show={showScope1_2Modal}
        onClose={() => setShowScope1_2Modal(false)}>
        <Scope1_2 onClose={() => setShowScope1_2Modal(false)} />
      </Modal.WithPortal>
    </section>
  );
}

export default MainLayout;
