import { useAmplitude } from 'customHooks/useAmplitude';
import LoaderCard from '../../ui/loaders/loaderCard/LoaderCard';
import FrameworkProgress from './components/frameworkProgress/FrameworkProgress';
import Metrics from './components/metrics/Metrics';
import useGetData from './hooks/useGetData';
import './styles.scss';
import { EVENTS } from 'constants/amplitude';
import { BaseOutputDataTable } from './components/dataTable/BaseOutputDataTable';
import { useFlags } from 'launchdarkly-react-client-sdk';

const TOTALS = {
  environmental: 502,
  social: 383,
  governance: 51
} as Record<string, number>;

const ControlPanel = () => {
  const flags = useFlags();
  useAmplitude(EVENTS.PAGE_VIEW);
  const { organizationESGStatus, organizationFrameworkStatus } = useGetData();
  return (
    <div className='dashboard__body-control-panel'>
      <FrameworkProgress
        data={organizationFrameworkStatus.data}
        loading={organizationFrameworkStatus.loading}
      />
      {!organizationFrameworkStatus.loading && !organizationESGStatus.loading ? (
        flags?.webinar060325 ? (
          <BaseOutputDataTable />
        ) : (
          <Metrics />
        )
      ) : (
        <LoaderCard />
      )}
    </div>
  );
};

export default ControlPanel;
