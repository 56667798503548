import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { registerUserInvited } from '../../../actions/auth';
import { PRIVACY_POLICY_URL } from '../../../constants';
import { ROUTES } from '../../../constants/routes';
import useOnChangeValue from '../../../customHooks/useOnChangeValue';
import { RegisterInfo1, User } from '../../../types/entities/user';
import checkFormErrors from '../../../utils/checkFormErrors';
import Button from '../../ui/button/Button';
import Checkbox from '../../ui/formComponents/checkbox/Checkbox';
import FormElementFull from '../../ui/formComponents/formElementFull/FormElementFull';
import FormWrapper from '../../ui/formComponents/formWrapper/FormWrapper';
import InputPhoneNumber from '../../ui/formComponents/inputPhoneNumber/InputPhoneNumber';
import InputText from '../../ui/formComponents/inputText/InputText';
import OnboardingImageWrapper from '../onboardingImageWrapper/OnboardingImageWrapper';
import './styles.scss';
import apiFetch from 'utils/apiFetch';

type FormData = {
  fullName: string;
  phone: PhoneNumber;
  privacyPolicyAccepted: boolean;
  newsletterAccepted: boolean;
  errors: ErrorType[];
};

const RegisterInvited = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const params = useParams();

  const [formData, setFormData] = useState<FormData>({
    fullName: '',
    phone: {
      phone: '',
      prefix: '+34'
    },
    privacyPolicyAccepted: false,
    newsletterAccepted: false,
    errors: []
  });
  const [loadingButton, setLoadingButton] = useState(false);

  const { onChangeValue } = useOnChangeValue({ setFormData });

  const handleErrors = () => {
    const optionalFields = ['errors', 'general', 'newsletterAccepted'];
    const newErrors: ErrorType[] = checkFormErrors(formData, [], optionalFields);

    setFormData((prev) => ({
      ...prev,
      errors: newErrors
    }));

    if (newErrors.length !== 0) return newErrors;
    return false;
  };

  const handleSubmitForm = () => {
    if (handleErrors()) return;

    const { id } = params;

    const splitName = formData.fullName.split(' ');

    const data: RegisterInfo1 = {
      first_name: splitName[0],
      last_name: splitName.length > 1 ? splitName[1] : '',
      prefix: formData.phone.prefix,
      phone_number: formData.phone.phone,
      accepted_terms_privacy: formData.privacyPolicyAccepted,
      accepted_comms_marketing: formData.newsletterAccepted
    };
    if (id) {
      registerUserInfo(id, data);
    }
  };

  const registerUserInfo = async (id: string, data: RegisterInfo1) => {
    setLoadingButton(true);

    const res = await apiFetch('get', `/users/${id}`, null, null);
    const user: User = res.data;

    if (!user || !user?.email || !user?.organizations || user?.organizations.length === 0) {
      setFormData({ ...formData, errors: [{ error: 'general' }] });
      setLoadingButton(false);
      return;
    }

    const email = user?.email;
    const organizationId = user?.organizations[0].id;

    const response: any = await dispatch(
      registerUserInvited(id, email, {
        ...data,
        organization_id: organizationId
      })
    );

    const newErrors = [...formData.errors].filter((elem) => elem.error !== 'general');
    setLoadingButton(false);
    if (response === 'INVALID_PHONE_NUMBER_PASSED') {
      newErrors.push({ error: 'phone', description: t('register.phoneNotValid') });
      setFormData({ ...formData, errors: newErrors });
    } else if (!response || response === 'Invalid data entry') {
      newErrors.push({ error: 'general' });
      setFormData({ ...formData, errors: newErrors });
    } else {
      navigate(ROUTES.IMPROVE_DASHBOARD);
    }
  };

  return (
    <OnboardingImageWrapper title={t('register.title')} subtitle={t('register.subtitle')}>
      <div className='register-form'>
        <FormWrapper>
          <FormElementFull>
            <InputText
              label={t('register.name')}
              icon='/images/icons/person.svg'
              placeholder={t('register.namePlaceholder')}
              value={formData.fullName}
              onChangeValue={onChangeValue('fullName')}
              error={formData.errors.find((elem) => elem.error === 'fullName')}
              size={'big'}
            />
          </FormElementFull>
          <FormElementFull>
            <InputPhoneNumber
              label={t('register.phone')}
              icon='/images/icons/phone.svg'
              placeholder={'000 000 000'}
              value={formData.phone.phone}
              valuePrefix={formData.phone.prefix}
              onChangeValue={onChangeValue('phone')}
              error={formData.errors.find((elem) => elem.error === 'phone')}
              size={'big'}
            />
          </FormElementFull>
          {/* <div className='newsletter-checkbox'>
            <Checkbox
              selected={formData.newsletterAccepted}
              onChange={onChangeValue('newsletterAccepted')}
              text={<span>{t('register.newsletterAccept')}</span>}
            />
          </div> */}
          <Checkbox
            selected={formData.privacyPolicyAccepted}
            onChange={onChangeValue('privacyPolicyAccepted')}
            text={
              <div className='privacy-policy-text'>
                <span>{t('register.acceptDataTreatment')}</span>
                <a
                  href={PRIVACY_POLICY_URL}
                  target='_blank'
                  className='highlight-text-color'
                  rel='noopener noreferrer'>
                  {t('register.privacyPolicy')}
                </a>
              </div>
            }
            error={formData.errors.find((elem) => elem.error === 'privacyPolicyAccepted')}
          />
        </FormWrapper>
        <div className='flex register-form__footer'>
          <Button
            lookAndFeel='primary'
            text={t('register.startToUse')}
            onClick={handleSubmitForm}
            loading={loadingButton}
          />
        </div>
        {formData.errors.find((elem) => elem.error === 'general') && (
          <span className='error-text error-font error-text-color'>
            {t('register.registerFail')}
          </span>
        )}
      </div>
    </OnboardingImageWrapper>
  );
};

export default RegisterInvited;
