export const gradient_color = 'linear-gradient(to right, #225e66, #225e66)';
export const gradient_color_secondary = 'linear-gradient(to right, #225e66, #225e66)';

export const primary_color = '#225e66';
export const primary_color_5opacity = 'rgb(169, 179, 248, 0.5)';

export const highlight_color = '#225e66';
export const highlight_color2 = '#225e66';
export const highlight_color2_5opacity = 'rgba(5, 83, 78, 0.5)';

export const secondary_bg_color = '#ffffff';
export const secondary_color = 'rgba(25, 42, 62, 0.8)';
export const secondary_border_color = '#d1d4d8';
export const secondary_purple_80 = '#F0F3FE';

export const scope1_color = '#225e66';
export const scope2_color = '#648e94';
export const scope3_color = '#a784ff';

export const scope1_color_rgb = 'rgb(5, 83, 78)';
export const scope2_color_rgb = 'rgb(39, 153, 146)';
export const scope3_color_rgb = 'rgb(169, 179, 248)';

export const fourth_color = '#ff2990';
export const fifth_color = '#6e86f7';

export const gray_color = '#d1d4d8';
export const gray_color2 = '#eaeaea';
export const blocked_color = '#6d32ff';
export const gray_dark_color = '#415062';
export const gray_dashboard = '#fafafa';
export const neutral_gray_warm_20 = '#c4c4c4';
export const neutral_gray_warm_40 = '#D8D1D1';
export const disabled_color = 'var(--disabled-color)';

export const warning_color = 'var(--warning-color)';
export const warning_bg_color = 'var(--warning-bg-color)';

export const main_bg_color = '#ffffff';

export const success_color = '#225e66';
export const error_color = '#FF2990';
export const error_color_text = '#f2a09d';
export const pending_color = 'var(--pending-color)';

// Pie chart Sustainability roadmap
export const darkest_blue = '#242e5e';
export const dark_blue = '#122bad';
export const darkest_purple = '#9b9de0';
export const dark_purple = '#acaefa';
