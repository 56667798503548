import { cloneElement, useContext, useEffect, useRef } from 'react';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import useSelectedOrganization from 'customHooks/useSelectedOrganization';
import { UserContext } from 'context/userContext';
import * as amplitude from '@amplitude/analytics-browser';
import { EVENTS } from 'constants/amplitude';
type Props = {
  children: React.ReactElement;
};
export const HeadwayBadge: React.FC<Props> = ({ children }) => {
  const hasLoaded = useRef(false);

  const { t } = useTranslation('translation', { keyPrefix: 'headwayBadge' });
  const selectedOrganization = useSelectedOrganization();
  const user = useContext(UserContext);
  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://cdn.headwayapp.co/widget.js';
    document.head.appendChild(script);
    const config = {
      selector: '#headway-badge-container',
      account: 'ypZO97',
      trigger: '#headway-badge-trigger',
      translations: {
        title: t('title'),
        readMore: t('readMore'),
        footer: t('footer'),
        labels: {
          new: t('new'),
          fix: t('fix'),
          improvement: t('improvement')
        }
      }
    };

    script.onload = function () {
      if (hasLoaded.current) return;

      hasLoaded.current = true;
      window.Headway.init(config);
    };

    return () => {
      document.head.removeChild(script);
    };
  });
  useEffect(() => {
    const handleClick = () => {
      const visible = document.getElementsByClassName('HW_visible');
      if (visible.length === 1) {
        amplitude.track(EVENTS.BUTTON_CLICKED, {
          org_id: selectedOrganization?.id,
          org_name: selectedOrganization?.company_name,
          is_internal: user?.email.includes('@dcycle.io') ? 'Internal user' : 'External user',
          button_clicked: 'Changelog clicked'
        });
      }
    };

    const badgeContainer = document.getElementById('headway-badge-trigger');

    if (badgeContainer) {
      badgeContainer.addEventListener('click', handleClick);
    }

    return () => {
      if (badgeContainer) {
        badgeContainer.removeEventListener('click', handleClick);
      }
    };
  }, []);

  return (
    <div id='headway-badge-container'>
      {cloneElement(children, { id: 'headway-badge-trigger' })}
    </div>
  );
};
