import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { months } from '../../../../constants/months';
import { useFeatureFlags } from '../../../../customHooks/useFeatureFlags';
import { createRandomDataChart } from '../../../../mocks/dashboardChart';
import { scope1_color, scope2_color, scope3_color } from '../../../../styles/colors';
import Button from '../../../ui/button/Button';
import LineChart2 from '../../../ui/charts/LineChart2';
import BarChartComparison from '../../../ui/charts/barChartComparison/BarChartComparison';
import LoaderCard from '../../../ui/loaders/loaderCard/LoaderCard';
import Tag from '../../../ui/tag/Tag';
import './styles.scss';
import { Month } from '../../../../types/utils/months';
import Icon from 'components/ui/icon/Icon';
import formatNumber from 'utils/formatNumber';
import numberToDecimal from 'utils/numberToDecimal';
import Label from 'components/ui/label/Label';

type Props = {
  data: YearComparisonData[];
  dataEvolution: EvolutionData[];
  // handleChangeStartDate: (date: Date) => void;
  // handleChangeEndDate: (date: Date) => void;
  startDate: Date;
  endDate: Date;
  disabled: boolean;
  loading: boolean;
  freeTrial: boolean;
  handleSwitchIsHolding?: () => void;
};
function Evolution({
  data,
  dataEvolution,
  startDate,
  endDate,
  disabled,
  loading,
  handleSwitchIsHolding,
  freeTrial
}: Props) {
  const { t, i18n } = useTranslation();
  const flags = useFeatureFlags();

  const formatDate = (date: Date) => {
    const dateParsed = moment(date).format('DD-MM-YYYY');
    const dateSplitted = dateParsed.split('-');
    const year = dateSplitted[2].slice(2);
    const month = parseInt(dateSplitted[1], 10);
    const day = dateSplitted[0];

    const monthFormatted = months.find((elem) => elem.number === month);
    const lang = i18n.resolvedLanguage as keyof Month['short'];
    return monthFormatted ? `${day} ${monthFormatted.short[lang]} ${year}` : '';
  };

  const renderDataEvolution = () => {
    // Get list of months between start and end date
    const dateStart = moment(startDate);
    const dateEnd = moment(endDate);
    const timeValues = [];

    while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
      timeValues.push({ x: dateStart.format('YYYY-MM'), y: 0 });
      dateStart.add(1, 'month');
    }

    type ChartData = {
      x: string;
      y: number;
    };
    const dataScope1: ChartData[] = JSON.parse(JSON.stringify(timeValues));
    dataEvolution
      .filter((elem: EvolutionData) => elem.scope === 1)
      .sort((a: EvolutionData, b: EvolutionData) => (a.date < b.date ? -1 : 1))
      .forEach((elem: EvolutionData) => {
        const foundIndex = dataScope1.findIndex(
          (elem2) => elem2.x === moment(new Date(elem.date)).format('YYYY-MM')
        );
        if (foundIndex > -1) {
          dataScope1[foundIndex].y += elem.total_emissions / 1000;
        }
      });

    const dataScope2: ChartData[] = JSON.parse(JSON.stringify(timeValues));
    dataEvolution
      .filter((elem: EvolutionData) => elem.scope === 2)
      .sort((a: EvolutionData, b: EvolutionData) => (a.date < b.date ? -1 : 1))
      .forEach((elem: EvolutionData) => {
        const foundIndex = dataScope2.findIndex(
          (elem2) => elem2.x === moment(new Date(elem.date)).format('YYYY-MM')
        );
        if (foundIndex > -1) {
          dataScope2[foundIndex].y += elem.total_emissions / 1000;
        }
      });
    const dataScope3: ChartData[] = JSON.parse(JSON.stringify(timeValues));

    dataEvolution
      .filter((elem: EvolutionData) => elem.scope === 3)
      .sort((a: EvolutionData, b: EvolutionData) => (a.date < b.date ? -1 : 1))

      .forEach((elem: EvolutionData) => {
        const foundIndex = dataScope3.findIndex(
          (elem2) => elem2.x === moment(new Date(elem.date)).format('YYYY-MM')
        );
        if (foundIndex > -1) {
          dataScope3[foundIndex].y += elem.total_emissions / 1000;
        }
      });

    return (
      <LineChart2
        labels={timeValues.map((elem) => {
          const date = moment(elem.x);
          const month = months.find((month) => month.number === date.month() + 1);
          const lang = i18n.resolvedLanguage as keyof Month['short'];
          return month ? `${month.short[lang]} ${date.format('YY')}` : '';
        })}
        chartData={
          disabled
            ? createRandomDataChart()
            : [
                {
                  label: 'scope1',
                  borderColor: scope1_color,
                  data: dataScope1.map((elem) => elem.y),
                  borderWidth: 1,
                  pointRadius: 0,
                  pointHoverRadius: 0,
                  stepped: false,
                  backgroundColor: 'rgba(41, 69, 255, 0.1)',
                  fill: true,
                  tension: 0
                },
                {
                  label: 'scope2',
                  borderColor: scope2_color,
                  data: dataScope2.map((elem) => elem.y),
                  borderWidth: 1,
                  pointRadius: 0,
                  pointHoverRadius: 0,
                  stepped: false,
                  backgroundColor: 'rgba(56, 207, 217, 0.1)',
                  fill: true,
                  tension: 0
                },
                {
                  label: 'scope3',
                  borderColor: scope3_color,
                  data: dataScope3.map((elem) => elem.y),
                  borderWidth: 1,
                  pointRadius: 0,
                  pointHoverRadius: 0,
                  stepped: false,
                  backgroundColor: 'rgba(206, 210, 250, 0.1)',
                  fill: true,
                  tension: 0
                }
              ]
        }
        disabled={disabled}
      />
    );
  };

  const renderDelta = (value: number) => {
    return flags?.prevYearBarChart ? (
      <Label lookAndFeel={value > 0 ? 'secondary' : 'secondary'}>
        <div className='icon-text-wrapper'>
          <img
            src={
              value > 0
                ? '/images/icons/arrowUpSecondary.svg'
                : '/images/icons/arrowDownPrimary.svg'
            }
            alt='arrow'
          />
          <span>
            {value > 0 ? '+' : ''}
            {formatNumber(numberToDecimal(value * 100))}%
          </span>
        </div>
      </Label>
    ) : (
      <></>
    );
  };

  if (loading) {
    return <LoaderCard />;
  }

  const dataSorted = data.sort((a, b) => (a.start_date > b.start_date ? 1 : -1));
  const co2PrevYear = dataSorted[0]
    ? {
        scope1: dataSorted[0].scope_1,
        scope2: dataSorted[0].scope_2,
        scope3: dataSorted[0].scope_3
      }
    : {
        scope1: 0,
        scope2: 0,
        scope3: 0
      };
  const co2CurrentYear = dataSorted[1]
    ? {
        scope1: dataSorted[1].scope_1,
        scope2: dataSorted[1].scope_2,
        scope3: dataSorted[1].scope_3
      }
    : {
        scope1: 0,
        scope2: 0,
        scope3: 0
      };

  const co2PrevYearTotal = co2PrevYear.scope1 + co2PrevYear.scope2 + co2PrevYear.scope3;

  const co2CurrentYearTotal = co2CurrentYear.scope1 + co2CurrentYear.scope2 + co2CurrentYear.scope3;

  // Compare the current year with the previous year by scopes and total to get delta
  const co2Delta = {
    scope1:
      co2CurrentYear.scope1 > 0 && co2PrevYear.scope1 > 0
        ? (co2CurrentYear.scope1 - co2PrevYear.scope1) / co2PrevYear.scope1
        : 0,
    scope2:
      co2CurrentYear.scope2 > 0 && co2PrevYear.scope2 > 0
        ? (co2CurrentYear.scope2 - co2PrevYear.scope2) / co2PrevYear.scope2
        : 0,
    scope3:
      co2CurrentYear.scope3 > 0 && co2PrevYear.scope3 > 0
        ? (co2CurrentYear.scope3 - co2PrevYear.scope3) / co2PrevYear.scope3
        : 0,
    total:
      co2CurrentYearTotal > 0 && co2PrevYearTotal > 0
        ? (co2CurrentYearTotal - co2PrevYearTotal) / co2PrevYearTotal
        : 0
  };

  const dataChart = [
    [
      {
        label: t('dashboard.total'),
        value: co2PrevYearTotal,
        color: 'gradient',
        opacity: 0.5
      },
      {
        label: t('dashboard.scope1'),
        value: co2PrevYear.scope1,
        color: scope1_color,
        opacity: 0.5
      },
      {
        label: t('dashboard.scope2'),
        value: co2PrevYear.scope2,
        color: scope2_color,
        opacity: 0.5
      },
      {
        label: t('dashboard.scope3'),
        value: co2PrevYear.scope3,
        color: scope3_color,
        opacity: 0.5
      }
    ],
    [
      {
        label: t('dashboard.total'),
        value: co2CurrentYearTotal * 20,
        color: 'gradient'
      },
      {
        label: t('dashboard.scope1'),
        value: co2CurrentYear.scope1,
        color: scope1_color
      },
      {
        label: t('dashboard.scope2'),
        value: co2CurrentYear.scope2,
        color: scope2_color
      },
      {
        label: t('dashboard.scope3'),
        value: co2CurrentYear.scope3,
        color: scope3_color
      }
    ]
  ];

  const startDatePreviousYear = moment(startDate).subtract(1, 'year').toDate();
  const endDatePreviousYear = moment(endDate).subtract(1, 'year').toDate();

  return (
    <div className='dashboard-card card small-device-flex' id='step3'>
      <div className='dashboard-card__header'>
        <h4 className='headline4-font on-light-text-color'>
          {t('dashboard.timeComparison', {
            period: `${formatDate(startDate)}-${formatDate(endDate)}`
          })}
        </h4>
        {freeTrial ? (
          <Button
            lookAndFeel='blocked'
            size='tiny'
            text={t('blockedButton.improvePlan')}
            onClick={() => {
              //
            }}
          />
        ) : null}
      </div>
      <div className='dashboard-card__body small-device-flex'>
        <div className='evolution-comparison-tag small-device-flex'>
          <Tag
            tags={[
              {
                icon1: '',
                text: <span className='on-light-text-color tag-font'>{t('dashboard.total')}</span>,
                text2: renderDelta(co2Delta.total)
              }
            ]}
          />
          <Tag
            tags={[
              {
                icon1: '/images/icons/dotScope1.svg',
                text: (
                  <span className='on-light-text-color tag-font'>{t('dashboard.scope1Short')}</span>
                ),
                text2: renderDelta(co2Delta.scope1)
              }
            ]}
          />
          <Tag
            tags={[
              {
                icon1: '/images/icons/dotScope2.svg',
                text: (
                  <span className='on-light-text-color tag-font'>{t('dashboard.scope2Short')}</span>
                ),
                text2: renderDelta(co2Delta.scope2)
              }
            ]}
          />
          <Tag
            tags={[
              {
                icon1: '/images/icons/dotScope3.svg',
                text: (
                  <span className='on-light-text-color tag-font'>{t('dashboard.scope3Short')}</span>
                ),
                text2: renderDelta(co2Delta.scope3)
              }
            ]}
          />
        </div>
        {flags?.prevYearBarChart ? (
          <>
            <div className='content-wrapper'>
              <BarChartComparison chartData={dataChart} disabled={disabled} />
              <div className='dates-legend'>
                <div className='dates-previous-year'>
                  <Tag
                    tags={[
                      {
                        icon1: '',
                        text: (
                          <span>
                            <Icon icon={'electricity'} color={'gray-warm'} />
                            {formatDate(startDatePreviousYear)} - {formatDate(endDatePreviousYear)}
                          </span>
                        )
                      }
                    ]}
                  />
                </div>
                <div className='dates-current-year'>
                  <Tag
                    tags={[
                      {
                        icon1: '',
                        text: (
                          <span>
                            <Icon icon={'electricity'} color={'gray-dark'} />
                            {formatDate(startDate)} - {formatDate(endDate)}
                          </span>
                        )
                      }
                    ]}
                  />
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className='chart-wrapper'>{renderDataEvolution()}</div>
        )}
      </div>
    </div>
  );
}

export default Evolution;
