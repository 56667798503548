import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../../constants/routes';
import { UserContext } from '../../../context/userContext';
import { useMITECOOrgEf } from '../../../customHooks/api/useMITECOOrgEf';
import { useFeatureFlags } from '../../../customHooks/useFeatureFlags';
import { checkFreePlan } from '../../../utils/checkFreePlan';
import SectionHeader from '../../layout/sectionHeader/SectionHeader';
import CardTooltipList from '../../ui/cardTooltipList/CardTooltipList';
import { ImportEmissionsBanner } from './components/ImportEmissionsBanner';
import { OutdatedEfBanner } from './components/OutdatedEfBanner';
import './styles.scss';
import useSelectedOrganization from 'customHooks/useSelectedOrganization';
import { PermissionTags } from 'types/entitiesEnums/permissions';
import useRole from 'customHooks/useRole';

function MeasureMainPage() {
  const { t } = useTranslation();
  const flags = useFeatureFlags();

  const { data: orgEf, isFetching } = useMITECOOrgEf();

  const selectedOrganization = useSelectedOrganization();
  const { permissions } = useRole();

  const cards: CardTooltip[] = [
    {
      id: 'facilities',
      data: [
        {
          icon: 'facilities',
          title: t('measureMain.facilities'),
          description: t('measureMain.facilitiesDescription'),
          route: ROUTES.MEASURE_FACILITIES
        }
      ],
      tooltip: t('measureMain.facilitiesTooltip'),
      disabled: false,
      freeTrial: false,
      group: [1, 2, 3]
    },
    {
      id: 'vehicles',
      data: [
        {
          icon: 'car',
          title: t('measureMain.vehicles'),
          description: t('measureMain.vehiclesDescription'),
          route: ROUTES.MEASURE_VEHICLES
        }
        // {
        //   icon: '/images/icons/truck.svg',
        //   title: t('measureMain.merchandiseVehicles'),
        //   description: t('measureMain.merchandiseVehiclesDescription'),
        //   total: freightVehiclesData
        //     ? freightVehiclesData.total_emissions
        //     : t('measureMain.noData'),
        //   unit: freightVehiclesData ? 't CO₂ eq.' : '',
        //   route: ROUTES.MEASURE_VEHICLES_FREIGHT
        // }
      ],
      tooltip: t('measureMain.vehiclesTooltip'),
      disabled: false,
      freeTrial: false,
      group: [1],
      blocked: !permissions?.find(
        (permission: Permission) => permission.tag === PermissionTags.EMISSION_VEHICLE_ALL
      )
    },
    {
      id: 'logistics',
      data: [
        {
          icon: 'lorry',
          title: t('measureMain.logistics'),
          description: t('measureMain.logisticsDescription'),
          route: ROUTES.MEASURE_LOGISTICS
        }
      ],
      tooltip: t('measureMain.logisticsTooltip'),
      disabled: false,
      freeTrial: false,
      group: [1, 2, 3]
    },
    {
      id: 'employees',
      data: [
        {
          icon: 'members',
          title: t('measureMain.employees'),
          description: t('measureMain.employeesDescription'),
          route: ROUTES.MEASURE_ACTIVITY_EMPLOYEES
        }
      ],
      tooltip: t('measureMain.employeesTooltip'),
      disabled: false,
      freeTrial: false,
      group: [3],
      blocked: !permissions?.find(
        (permission: Permission) => permission.tag === PermissionTags.EMISSION_EMPLOYEE_ALL
      )
    },
    {
      id: 'businessTravels',
      data: [
        {
          icon: 'airplane',
          title: t('measureMain.travels'),
          description: t('measureMain.travelsDescription'),
          route: ROUTES.MEASURE_ACTIVITY_BUSINESS_TRAVELS
        }
      ],
      tooltip: t('measureMain.travelsTooltip'),
      disabled: false,
      freeTrial: false,
      group: [3],
      blocked: !permissions?.find(
        (permission: Permission) => permission.tag === PermissionTags.EMISSION_BUSINESS_TRAVEL_ALL
      )
    },
    {
      id: 'shipments',
      data: [
        {
          icon: 'box',
          title: t('measureMain.shipments'),
          description: t('measureMain.shipmentsDescription'),
          route: ROUTES.MEASURE_ACTIVITY_SHIPMENTS + '?transport_direction=downstream'
        }
      ],
      tooltip: t('measureMain.transportDownstreamTooltip'),
      disabled: false,
      freeTrial: false,
      group: [3],
      blocked: !permissions?.find(
        (permission: Permission) =>
          permission.tag === PermissionTags.EMISSION_TRANSPORT_DOWNSTREAM_ALL
      )
    },
    {
      id: 'deliveries',
      data: [
        {
          icon: 'lorry',
          title: t('measureMain.deliveries'),
          description: t('measureMain.deliveriesDescription'),
          route: ROUTES.MEASURE_ACTIVITY_SHIPMENTS + '?transport_direction=upstream'
        }
      ],
      tooltip: t('measureMain.transportUpstreamTooltip'),
      disabled: false,
      freeTrial: false,
      group: [3],
      blocked: !permissions?.find(
        (permission: Permission) =>
          permission.tag === PermissionTags.EMISSION_TRANSPORT_UPSTREAM_ALL
      )
    },
    {
      id: 'purchases',
      data: [
        {
          icon: 'bag',
          title: t('measureMain.purchases'),
          description: t('measureMain.purchasesDescription'),
          route: ROUTES.MEASURE_ACTIVITY_PURCHASES
        }
      ],
      tooltip: t('measureMain.purchasesTooltip'),
      disabled: false,
      freeTrial: checkFreePlan(selectedOrganization),
      group: [3],
      blocked: !permissions?.find(
        (permission: Permission) => permission.tag === PermissionTags.EMISSION_PURCHASE_ALL
      )
    },
    {
      id: 'esgMetrics',
      data: [
        {
          icon: 'earth',
          title: t('measureMain.esgMetrics'),
          description: t('measureMain.esgMetricsDescription'),
          route: ROUTES.MEASURE_ESG_METRICS
        }
      ],
      tooltip: t('measureMain.esgMetricsTooltip'),
      disabled: false,
      freeTrial: checkFreePlan(selectedOrganization),
      group: ['CSRD']
    },
    {
      id: 'socialAndGovernance',
      data: [
        {
          icon: 'earth',
          title: t('measureMain.socialAndGovernance'),
          description: t('measureMain.socialAndGovernanceDescription'),
          route: ROUTES.MEASURE_ACTIVITY_SOCIAL_AND_GOVERNANCE
        }
      ],
      tooltip: t('measureMain.socialAndGovernanceTooltip'),
      disabled: false,
      freeTrial: false,
      group: [],
      blocked: !permissions?.find(
        (permission: Permission) => permission.tag === PermissionTags.EMISSION_SOCIAL_GOVERNANCE_ALL
      )
    },
    {
      id: 'events',
      data: [
        {
          icon: 'location',
          title: t('measureMain.events'),
          description: t('measureMain.eventsDescription'),
          route: ROUTES.MEASURE_EVENTS
        }
      ],
      tooltip: t('measureMain.eventsTooltip'),
      disabled: false,
      freeTrial: false,
      group: []
    },
    {
      id: 'useAndEndOfLife',
      data: [
        {
          icon: 'layers',
          title: t('measureMain.useAndEndOfLife'),
          description: t('measureMain.useAndEndOfLifeDescription'),
          route: ROUTES.MEASURE_ACTIVITY_USE_AND_END_OF_LIFE
        }
      ],
      tooltip: t('measureMain.useAndEndOfLifeTooltip'),
      disabled: false,
      freeTrial: checkFreePlan(selectedOrganization),
      group: [3]
    }
    // {
    //   id: 'waste',
    //   data: [
    //     {
    //       icon: '/images/icons/recycle.svg',
    //       title: t('measureMain.waste'),
    //       description: t('measureMain.wasteDescription'),
    //       total: wasteData ? wasteData.total_emissions : t('measureMain.noData'),
    //       unit: wasteData ? 'kg CO2 eq.' : '',
    //       route: ROUTES.MEASURE_ACTIVITY_WASTE
    //     }
    //   ],
    //   tooltip: t('measureMain.wasteTooltip'),
    //   tooltipPosition: 'right',
    //   disabled: true,
    //   freeTrial: false,
    //   scopes: [3]
    // }
  ];

  const featureFlag = (array: typeof cards) => {
    return array.filter(
      (element) =>
        !Object.keys(flags as object).includes(element.id) ||
        (flags && flags[element.id as keyof typeof flags])
    );
  };

  const cardsFeatureFlags = featureFlag(cards);
  const cardsFilteredBySector = cardsFeatureFlags.filter((card) => {
    if (selectedOrganization?.sector !== 'transport' && card.id === 'logistics') return false;
    return true;
  });

  return (
    <section className='measure-main'>
      <SectionHeader title={t('measureMain.title')} subtitle={t('measureMain.subtitle')} />
      {flags?.updateEfs && !isFetching && !orgEf?.latest && <OutdatedEfBanner />}
      {flags?.importEmissions && <ImportEmissionsBanner />}
      <CardTooltipList cards={cardsFilteredBySector} />
    </section>
  );
}

export default MeasureMainPage;
